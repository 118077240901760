import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import Button from "../components/form/button"
import Layout from "../components/layout"
import Head from "../components/header/head"
import Slider from "../components/slider/slider"
import Heading from "../components/form/heading"
import type { Post } from "../common/post.model"
import { type PostType, POST_TYPES } from "../common/post-type.model"
import PostCard from "../components/cards/post-card"

const HomePage = () => {
  const postsData = useStaticQuery(graphql`
    query {
      disciplesBios: allContentfulPost(
        filter: { slug: { in: ["shaykh-bahauddin", "shaykh-nurjan", "shaykh-adnan", "shaykh-hisham-kabbani"] } }
      ) {
        edges {
          node {
            title
            slug
            city
            publishedDate(formatString: "DD/MM/YYYY", locale: "fr")
            image {
              gatsbyImageData(height: 576, placeholder: BLURRED)
            }
            category {
              name
            }
            postType {
              typeId
            }
          }
        }
      }
    }
  `)

  const disciplesBios: Post[] = postsData.disciplesBios.edges.map((edge: any) => edge.node)
  return (
    <Layout headerColor="" headerSubTitle="" headerTitle="" headerPageName="Home">
      <Head title="Home" description="La Voie des Maîtres: les disciples-éducateurs"/>
      { 
        disciplesBios?.length ?  
          <div>
            <div className="mt-4 md:mt-0">
            <Heading>Les disciples éducateurs :</Heading>
            </div>
            <div className="mt-8 grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-8 justify-items-center">
                {disciplesBios.map((post, index) => {
                return (
                    <div key={`res-${index}`}>
                    <PostCard post={post} size="mobile-large"></PostCard>
                    </div>
                )
                })}
            </div>
          </div>
        : <></>
      }
    </Layout>
  )
}
export default HomePage
